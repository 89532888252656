import { isAuthenticationNeeded } from '@/router/utils';
import DocumentSchema from '../../DocumentSchema';
import type { Organization } from 'schema-dts';
import { useSelector } from 'react-redux';
import { getLocationType } from '@/redux/location/selectors';

export const SimplyWallStSchema = () => {
  const type = useSelector(getLocationType);

  if (isAuthenticationNeeded(type)) return null;

  return (
    <DocumentSchema<Organization>
      item={{
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Simply Wall St',
        legalName: 'Simply Wall St',
        description:
          'Make confident investment decisions and stay up to date on 100k+ stocks with our unique fundamental analysis and in-depth visual reports.',
        logo: 'https://simplywall.st/news/images/sws-logo.svg',
        url: 'https://simplywall.st/',
        sameAs: [
          'https://twitter.com/simplywallst',
          'https://www.linkedin.com/company/simply-wall-st',
          'https://www.facebook.com/simplywallst/',
          'https://www.youtube.com/c/simplywallst',
        ],
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Level 7, 320 Pitt Street',
          addressLocality: 'Sydney',
          postalCode: '2000',
          addressCountry: 'Australia',
        },
      }}
    />
  );
};
