import {
  BRAZE_PROD_API_KEY,
  BRAZE_PROD_SDK_ENDPOINT,
  BRAZE_DEV_API_KEY,
  BRAZE_DEV_SDK_ENDPOINT,
} from '@/constants/env';

/*
 *
 *
 * 👋
 * To test Braze locally, set this variable to `true`.
 * From there, refer to this Guide https://gitlab.com/simplywallst/frontend/mono/-/wikis/Braze. */
const ENABLE_LOCAL_TESTING = false;
/*
 *
 *
 * end */

export const ENABLE_BRAZE: boolean =
  NODE_ENV === 'production' ? true : ENABLE_LOCAL_TESTING;

type BrazeConfigType = 'test' | 'dev' | 'prod';

type BrazeConfigurationsType = {
  [key in BrazeConfigType]: {
    API_KEY: string;
    SDK_ENDPOINT: string;
  };
};

export const BRAZE_CONFIGURATIONS: BrazeConfigurationsType = {
  test: {
    API_KEY: 'xxxyyyzzz',
    SDK_ENDPOINT: 'braze.mock',
  },
  dev: {
    API_KEY: BRAZE_DEV_API_KEY,
    SDK_ENDPOINT: BRAZE_DEV_SDK_ENDPOINT,
  },
  prod: {
    API_KEY: BRAZE_PROD_API_KEY,
    SDK_ENDPOINT: BRAZE_PROD_SDK_ENDPOINT,
  },
};

/*
 *
 *
 * 👋
 * To change the Braze instance (you probably don't need to), set this value. */
export const ENABLED_CONFIGURATION_KEY: BrazeConfigType = 'prod';
/*
 *
 *
 * end */

if (NODE_ENV === 'development') {
  console.log('Braze:', {
    enabled: ENABLE_BRAZE,
    config_key: ENABLED_CONFIGURATION_KEY,
  });
  if (!ENABLE_BRAZE) {
    console.log('Braze: Can be enabled at App/components/Braze/config.ts');
  }
}
