import { useCallback } from 'react';
import { useBrazeWebSDK } from '../context/BrazeContext';
import { ENABLE_BRAZE } from '@components/Braze/config';
import { captureException } from '@sentry/react';
import { useBrazeNativeSDK } from './useBrazeNativeSDK';
import type { InAppMessage, ControlMessage } from '@braze/web-sdk';

export function useBraze() {
  const brazeWebSDK = useBrazeWebSDK();
  const brazeNativeSDK = useBrazeNativeSDK();

  const logCustomEvent = useCallback(
    (eventName: string, eventProperties?: Record<string, unknown>): boolean => {
      if (ENABLE_BRAZE) {
        console.log('Braze:logCustomEvent', { eventName, eventProperties });
        try {
          if (
            RUNTIME_ENV === 'native' &&
            typeof brazeNativeSDK !== 'undefined'
          ) {
            return brazeNativeSDK.logCustomEvent(eventName, eventProperties);
          }
          if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
            return brazeWebSDK.logCustomEvent(eventName, eventProperties);
          }
        } catch (error) {
          console.error('Braze:logCustomEvent - error', { error });
          captureException(error);
        }
      }
      return false;
    },
    [brazeWebSDK, brazeNativeSDK]
  );

  const subscribeToContentCardsUpdates = useCallback(
    (fn) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        const unsubscribeId = brazeWebSDK.subscribeToContentCardsUpdates(fn);
        brazeWebSDK.requestContentCardsRefresh();
        return unsubscribeId;
      }
    },
    [brazeWebSDK]
  );

  const removeSubscription = useCallback(
    (id) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        brazeWebSDK.removeSubscription(id);
      }
    },
    [brazeWebSDK]
  );

  const logCardDismissal = useCallback(
    (card) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        brazeWebSDK.logCardDismissal(card);
      }
    },
    [brazeWebSDK]
  );

  const logClickedCard = useCallback(
    (card) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        brazeWebSDK.logCardClick(card, true);
      }
    },
    [brazeWebSDK]
  );

  const logCardImpressions = useCallback(
    (card) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        brazeWebSDK.logCardImpressions(card, true);
      }
    },
    [brazeWebSDK]
  );

  const subscribeToInAppMessage = useCallback(
    (subscribeHandler: (message: InAppMessage | ControlMessage) => void) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        return brazeWebSDK.subscribeToInAppMessage((message) => {
          subscribeHandler(message);
        });
      }
      return undefined;
    },
    [brazeWebSDK]
  );

  const unsubscribeToInAppMessage = useCallback(
    (id: string) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        brazeWebSDK.removeSubscription(id);
      }
    },
    [brazeWebSDK]
  );

  const showInAppMessage = useCallback(
    (inAppMessage: InAppMessage | ControlMessage) => {
      if (RUNTIME_ENV === 'browser' && typeof brazeWebSDK !== 'undefined') {
        brazeWebSDK.showInAppMessage(inAppMessage);
      }
    },
    [brazeWebSDK]
  );

  return {
    logCustomEvent,
    subscribeToContentCardsUpdates,
    removeSubscription,
    logCardDismissal,
    logClickedCard,
    logCardImpressions,
    subscribeToInAppMessage,
    unsubscribeToInAppMessage,
    showInAppMessage,
  };
}
