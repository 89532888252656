/**
 *
 * On native apps `BrazePlugin` is available on the window object
 * @returns BrazePlugin | undefined
 *
 */
export function useBrazeNativeSDK() {
  if (RUNTIME_ENV !== 'native') return undefined;
  if (typeof window.BrazePlugin !== 'undefined') {
    return window.BrazePlugin;
  }
}
