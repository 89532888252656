import { useEffect, useRef } from 'react';
import useUser from '@/hooks/useUser';
import type { BrazeWebSDKType } from '../types';

type Props = { braze: BrazeWebSDKType };

const BrazeWeb = ({ braze }: Props) => {
  const idRef = useRef<string | null>(null);
  const { data: profile } = useUser();

  /**
   * Change Braze user data if not native, user logged in and id is not null or current
   */
  useEffect(() => {
    if (typeof profile === 'undefined') return;
    if (profile.loggedIn === false && idRef.current !== null) {
      idRef.current = null;
      /**
       * As a result, we recommend against changing the user ID just because your app has entered a
       * "logged out" state because it makes you unable to target the previously logged out user with
       * re-engagement campaigns.
       */
      return;
    }
    if (profile.loggedIn && idRef.current !== profile.id) {
      // Web braze integration
      braze.changeUser(profile.id);

      braze.openSession();
      idRef.current = profile.id;
    }
  }, [profile, braze]);

  /**
   * Set Braze user
   */
  useEffect(() => {
    if (typeof profile === 'undefined') return;
    if (profile.loggedIn && idRef.current === profile.id) {
      // Set Braze user attrs
      const user = braze.getUser();
      user?.setFirstName(profile.givenName);
      user?.setEmail(profile.emailAddress);
    }
  }, [profile, braze]);

  return null;
};

export default BrazeWeb;
