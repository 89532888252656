import { Component } from 'react';
import type { WithContext, Thing, Organization } from 'schema-dts';
import { Helmet } from 'react-helmet';

export const SWSOrganizationSchema: Organization = {
  '@type': 'Organization',
  name: 'Simply Wall St',
};

export const SWSOrganizationAuthorSchema = {
  '@type': 'Organization',
  name: 'Simply Wall St',
  legalName: 'Simply Wall St',
  url: 'https://simplywall.st/',
  logo: 'https://simplywall.st/news/images/sws-logo.svg',
} as const;

/**
 * https://github.com/google/react-schemaorg/blob/master/src/json-ld.tsx
 *
 * This is a mod of the original due to Helmet not accepting dangerouslySetInnerHTML
 */
class DocumentSchema<T extends Thing> extends Component<{
  item: WithContext<T>;
  id?: string;
}> {
  render() {
    const { item, id } = this.props;
    return (
      <Helmet>
        <script type="application/ld+json" key={id}>
          {JSON.stringify(item)}
        </script>
      </Helmet>
    );
  }
}

export default DocumentSchema;
