import { ENABLE_BRAZE } from '../config';

import type { ReactElement } from 'react';
import { createContext, useContext, useState } from 'react';
import loadable from '@loadable/component';
import type { BrazeWebSDKType } from '../types';
/** code-splitting is now innate from within BrazeNative */
import { BrazeNative } from '@components/Braze/components/Native';

const BrazeWeb = loadable(() => import('@components/Braze/components/Web'), {
  ssr: false,
});

const BrazeWebInitialize = loadable(
  () => import('@components/Braze/components/BrazeWebInitialize'),
  {
    ssr: false,
  }
);

export const BrazeContext = createContext<BrazeWebSDKType | undefined>(
  undefined
);

export const BrazeProvider = ({ children }: { children?: ReactElement }) => {
  const [braze, setBraze] = useState<BrazeWebSDKType | undefined>(undefined);

  if (!ENABLE_BRAZE) {
    return children || null;
  }

  return (
    <BrazeContext.Provider value={braze}>
      {children}
      {RUNTIME_ENV === 'native' ? (
        <BrazeNative />
      ) : (
        <>
          <BrazeWebInitialize onInitialize={setBraze} />
          {braze ? <BrazeWeb braze={braze} /> : null}
        </>
      )}
    </BrazeContext.Provider>
  );
};

export const useBrazeWebSDK = () => {
  return useContext(BrazeContext);
};
